<script>
import { useI18n } from '@/modules/i18n';
import { useApi } from '@/modules/api';
import apiPerson from '../../api/person.js';

import V3PersonRelativeForm from '../V3PersonRelativeForm/V3PersonRelativeForm.vue';

export default {
  name: 'V3PersonRelativeEditor',

  components: { V3PersonRelativeForm },

  mixins: [useApi, useI18n],
  api: apiPerson,

  props: {
    person: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      canonicalValue: null,
      ignoreExternalChanges: false,

      innerPerson: null,
    };
  },

  watch: {
    person: {
      immediate: true,
      handler() {
        if (this.ignoreExternalChanges) {
          this.ignoreExternalChanges = false;
          return;
        }

        this.canonicalValue = { ...this.person };
        this.innerPerson = { ...this.canonicalValue };
      },
    },

    innerPerson: {
      deep: true,
      handler() {
        this.ignoreExternalChanges = true;
        this.$emit('update:person', { ...this.innerPerson });
      },
    },
  },

  methods: {
    async onSubmit() {
      try {
        await this.$api.putPerson(this.person.id, this.innerPerson);
        this.$emit('update:person', { ...this.innerPerson });
        this.$emit('updated');
      } catch (err) {
        alert('Error!');
      }
    },

    cancel() {
      this.innerPerson = { ...this.canonicalValue };
      this.$emit('update:person', { ...this.innerPerson });
      this.$emit('cancel');
    },
  },
};
</script>

<template>
  <form
    class="V3PersonRelativeEditor"
    @submit.prevent="onSubmit"
  >
    <v3-person-relative-form :person.sync="innerPerson"></v3-person-relative-form>
    <footer>
      <button
        class="ui-button"
        type="submit"
        v-text="$t('common.Accept')"
      ></button>
      <button
        type="button"
        class="ui-button --cancel"
        @click="cancel"
        v-text="$t('common.Cancel')"
      ></button>
    </footer>
  </form>
</template>