<script>
import { useI18n } from '@/modules/i18n';
import { useApi } from '@/modules/api';
import { UiItem, UiDialog } from '@/modules/ui/components';

import apiPerson from '../../api/person.js';
import PersonItem from '../../../core/components/Person/ListItem.vue';

import V3PersonRelativeEditor from '../V3PersonRelativeEditor/V3PersonRelativeEditor.vue';
import V3PersonRelativeForm from '../V3PersonRelativeForm/V3PersonRelativeForm.vue';

export default {
  name: 'V3PersonRelativeManager',
  mixins: [useApi, useI18n],
  api: apiPerson,
  components: {
    PersonItem,
    V3PersonRelativeEditor,
    V3PersonRelativeForm,

    UiItem,
    UiDialog,
  },

  props: {
    personId: {
      type: String,
      required: true,
    },

    max: {
      type: [String, Number],
      required: false,
      default: Infinity,
    },
  },

  data() {
    return {
      children: [],
      spouses: [],

      newRelative: null,
      errors: [],
    };
  },

  watch: {
    personId: {
      immediate: true,
      handler() {
        this.fetchAllRelatives();
      },
    },
  },

  methods: {
    fetchAllRelatives() {
      this.$api
        .getPersonChildren(this.personId)
        // .getPersonChildren(this.personId, { enrollmentStatus: 32 })
        .then((response) => (this.children = response));

      this.$api.getPersonSpouses(this.personId).then((response) => {
        this.spouses = response;
        this.$emit('count', this.spouses ? this.spouses.length : 0);
      });
    },

    async createSpouse() {
      if (!this.newRelative) {
        return;
      }

      this.newRelative.firstname =
        this.newRelative.firstname?.trim().replace(/\s+/g, ' ') || '';

      this.newRelative.lastname =
        this.newRelative.lastname?.trim().replace(/\s+/g, ' ') || '';

      this.newRelative.lastname2 =
        this.newRelative.lastname2?.trim().replace(/\s+/g, ' ') || '';

      // var spouse = this.newRelative;
      this.newRelative.children = this.newRelative.children;

      this.newRelative.lastname1 = this.newRelative.lastname;
      this.newRelative.lastname2 = this.newRelative.lastname2;
      this.newRelative.lastname =
        this.newRelative.lastname1 + ' ' + this.newRelative.lastname2;

      // Asegurarse de incluir el "LANGUAGE" actual como valor predeterminado de idioma de la persona creada
      // this.newRelative.language = "{'LANGUAGE'|config}";

      this.newRelative.children = this.children.length
        ? this.children.map((c) => c.person.id)
        : null;

      const response = await this.$api.createSpouse(
        this.personId,
        this.newRelative
      );

      if (response.errors) {
        this.errors = response.errors;
        return false;
      }

      this.spouses.push(response);
      this.newRelative = null;
      this.$emit('count', this.spouses ? this.spouses.length : 0);

      return true;
    },
  },

  i18n: {
    en: {
      'V3PersonRelativeManager.createNewRelative': 'Add guardian *',
      'V3PersonRelativeManager.creatorSubtext':
        'You must add a second guardian to continue',

      'V3PersonRelativeManager.relation': 'Relation',
      'V3PersonRelativeManager.role.parent': 'Father/Mother',
      'V3PersonRelativeManager.role.sibling': 'Brother/Sister',
      'V3PersonRelativeManager.role.uncle': 'Uncle/Aunt',
      'V3PersonRelativeManager.role.grandparent': 'Grandfather/Grandmother',
      'V3PersonRelativeManager.role.stepparent': 'Stepfather/Stepmother',
      'V3PersonRelativeManager.role.other': 'Other',
    },

    es: {
      'V3PersonRelativeManager.createNewRelative': 'Crear nuevo familiar *',
      'V3PersonRelativeManager.creatorSubtext':
        'Debes crear un familiar para continuar',

      'V3PersonRelativeManager.relation': 'Parentesco',
      'V3PersonRelativeManager.role.parent': 'Padre/Madre',
      'V3PersonRelativeManager.role.sibling': 'Hermano/Hermana',
      'V3PersonRelativeManager.role.uncle': 'Tio/Tia',
      'V3PersonRelativeManager.role.grandparent': 'Abuelo/Abuela',
      'V3PersonRelativeManager.role.stepparent': 'Padrastro/Madrastra',
      'V3PersonRelativeManager.role.other': 'Otro',
    },

    de: {
      'V3PersonRelativeManager.createNewRelative':
        'Erziehungsberechtigten hinzufügen *',
      'V3PersonRelativeManager.creatorSubtext':
        'Sie müssen einen zweiten Erziehungsberechtigten hinzufügen, um fortzufahren',

      'V3PersonRelativeManager.relation': 'beziehung',
      'V3PersonRelativeManager.role.parent': 'Vater/Mutter',
      'V3PersonRelativeManager.role.sibling': 'Bruder/Schwester',
      'V3PersonRelativeManager.role.uncle': 'onkel/tante',
      'V3PersonRelativeManager.role.grandparent': 'Großvater/Großmutter',
      'V3PersonRelativeManager.role.stepparent': 'Stiefvater/Stiefmutter',
      'V3PersonRelativeManager.role.other': 'sonstige',
    },

    fr: {
      'V3PersonRelativeManager.createNewRelative': 'Ajouter un tuteur légal *',
      'V3PersonRelativeManager.creatorSubtext':
        'Vous devez ajouter un deuxième parent pour pouvoir continuer',

      'V3PersonRelativeManager.relation': 'relation',
      'V3PersonRelativeManager.role.parent': 'Père/mère',
      'V3PersonRelativeManager.role.sibling': 'frère/sœur',
      'V3PersonRelativeManager.role.uncle': 'oncle/tante',
      'V3PersonRelativeManager.role.grandparent': 'grand-père/grand-mère',
      'V3PersonRelativeManager.role.stepparent': 'beau-père/belle-mère',
      'V3PersonRelativeManager.role.other': 'autre',
    },
  },
};
</script>

<template>
  <div class="V3PersonRelativeManager">
    <div
      v-for="(spouse,i) in spouses"
      :key="spouse.id"
    >
      <ui-dialog>
        <template #trigger>
          <person-item
            :person="spouse"
            class="ui-clickable"
          ></person-item>
        </template>
        <template #contents="{ close }">
          <v3-person-relative-editor
            :person.sync="spouses[i]"
            @updated="close()"
            @cancel="close()"
          ></v3-person-relative-editor>
        </template>
      </ui-dialog>
    </div>

    <ui-dialog v-if="spouses.length < max">
      <template #trigger>
        <ui-item
          :text="$t('V3PersonRelativeManager.createNewRelative')"
          :secondary="$t('V3PersonRelativeManager.creatorSubtext')"
          icon="mdi:plus"
          class="ui-clickable"
        ></ui-item>
      </template>
      <template #contents="{ close }">
        <form @submit.prevent="createSpouse().then((ok) => ok && close())">

          <v3-person-relative-form :person.sync="newRelative"></v3-person-relative-form>

          <div class="V3PersonRelativeForm__field">
            <label v-text="$t('V3PersonRelativeManager.relation')"></label>
            <select
              :value="newRelative && newRelative.relation ? newRelative.relation : '1'"
              @input="newRelative = { ...newRelative, relation: $event.target.value}"
            >
              <option value="1">{{ $t('V3PersonRelativeManager.role.parent') }}</option>
              <option value="2">{{ $t('V3PersonRelativeManager.role.sibling') }}</option>
              <option value="3">{{ $t('V3PersonRelativeManager.role.uncle') }}</option>
              <option value="4">{{ $t('V3PersonRelativeManager.role.grandparent') }}</option>
              <option value="7">{{ $t('V3PersonRelativeManager.role.stepparent') }}</option>
              <option value="6">{{ $t('V3PersonRelativeManager.role.other') }}</option>
            </select>
          </div>

          <ul v-if="errors.length">
            <li
              v-for="(err,i) in errors"
              :key="i"
              v-text="err.message"
            ></li>
          </ul>

          <button
            type="submit"
            class="ui-button"
            v-text="$t('common.Accept')"
          ></button>
          <button
            type="button"
            class="ui-button --cancel"
            @click="newRelative = null; close()"
            v-text="$t('common.Cancel')"
          ></button>
        </form>
      </template>
    </ui-dialog>
  </div>
</template>